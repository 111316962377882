/*
Version: 3.4.8 Timestamp: Thu May  1 09:50:32 EDT 2014
*/
.select2-container {
    margin: 0;
    position: relative;
    display: inline-block;
    /* inline-block for ie7 */
    zoom: 1;
    vertical-align: middle;
}

.select2-container,
.select2-drop,
.select2-search,
.select2-search input {
    box-sizing: border-box;
}

.select2-container .select2-choice {
    display: block;
    height: 26px;
    padding: 0 0 0 8px;
    overflow: hidden;
    position: relative;

    border: 1px solid color(base-500);
    white-space: nowrap;
    line-height: 26px;
    color: color(base-605);
    text-decoration: none;

    border-radius: 4px;

    background-clip: padding-box;

    user-select: none;

    background-color: color(base-000);
    /* autoprefixer: off */
    background-image: linear-gradient(to top, #{color(base-300)} 0%, #{color(base-000)} 50%);
}

.select2-container.select2-drop-above .select2-choice {
    border-bottom-color: color(base-500);

    border-radius: 0 0 4px 4px;
    /* autoprefixer: off */
    background-image: linear-gradient(to bottom, #{color(base-300)} 0%, #{color(base-000)} 90%);
}

.select2-container.select2-allowclear .select2-choice .select2-chosen {
    margin-right: 42px;
}

.select2-container .select2-choice > .select2-chosen {
    margin-right: 26px;
    display: block;
    overflow: hidden;

    white-space: nowrap;

    text-overflow: ellipsis;
    float: none;
    width: auto;
}

.select2-container .select2-choice abbr {
    display: none;
    width: 12px;
    height: 12px;
    position: absolute;
    right: 24px;
    top: 8px;

    font-size: 1px;
    text-decoration: none;

    border: 0;
    background: url('../img/select2.png') right top no-repeat;
    cursor: pointer;
    outline: 0;
}

.select2-container.select2-allowclear .select2-choice abbr {
    display: inline-block;
}

.select2-container .select2-choice abbr:hover {
    background-position: right -11px;
    cursor: pointer;
}

.select2-drop-mask {
    border: 0;
    margin: 0;
    padding: 0;
    position: fixed;
    left: 0;
    top: 0;
    min-height: 100%;
    min-width: 100%;
    height: auto;
    width: auto;
    opacity: 0;
    z-index: 9998;
    /* styles required for IE to work */
    background-color: color(base-000);
}

.select2-drop {
    width: 100%;
    margin-top: -1px;
    position: absolute;
    z-index: 9999;
    top: 100%;

    background: color(base-000);
    color: color(base-700);
    border: 1px solid color(base-500);
    border-top: 0;

    border-radius: 0 0 4px 4px;

    box-shadow: 0 4px 5px colorAlpha(base-700, 0.15);
}

.select2-drop.select2-drop-above {
    margin-top: 1px;
    border-top: 1px solid color(base-500);
    border-bottom: 0;

    border-radius: 4px 4px 0 0;

    box-shadow: 0 -4px 5px colorAlpha(base-700, 0.15);
}

.select2-drop-active {
    border: 1px solid color(primary-300);
    border-top: none;
}

.select2-drop.select2-drop-above.select2-drop-active {
    border-top: 1px solid color(primary-300);
}

.select2-drop-auto-width {
    border-top: 1px solid color(base-500);
    width: auto;
}

.select2-drop-auto-width .select2-search {
    padding-top: 4px;
}

.select2-container .select2-choice .select2-arrow {
    display: inline-block;
    width: 18px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;

    border-left: 1px solid color(base-500);
    border-radius: 0 4px 4px 0;

    background-clip: padding-box;

    background: color(base-305);
    /* autoprefixer: off */
    background-image: linear-gradient(to top, #{color(base-305)} 0%, #{color(base-300)} 60%);
}

.select2-container .select2-choice .select2-arrow b {
    display: block;
    width: 100%;
    height: 100%;
    background: url('../img/select2.png') no-repeat 0 1px;
}

.select2-search {
    display: inline-block;
    width: 100%;
    min-height: 26px;
    margin: 0;
    padding-left: 4px;
    padding-right: 4px;

    position: relative;
    z-index: 10000;

    white-space: nowrap;
}

.select2-search input {
    width: 100%;
    height: auto;
    min-height: 26px;
    padding: 4px 20px 4px 5px;
    margin: 0;

    outline: 0;
    font-family: sans-serif;
    font-size: 1em;

    border: 1px solid color(base-500);
    border-radius: 0;

    box-shadow: none;
    /* autoprefixer: off */
    background: #{color(base-000)} url('../img/select2.png') no-repeat 100% -22px;
    background: url('../img/select2.png') no-repeat 100% -22px,
        linear-gradient(to bottom, #{color(base-000)} 85%, #{color(base-300)} 99%) 0 0;
}

.select2-drop.select2-drop-above .select2-search input {
    margin-top: 4px;
}

.select2-search input.select2-active {
    /* autoprefixer: off */
    background: #{color(base-000)} url('../img/select2-spinner.gif') no-repeat 100%;
    background: url('../img/select2-spinner.gif') no-repeat 100%,
        linear-gradient(to bottom, #{color(base-000)} 85%, #{color(base-300)} 99%) 0 0;
}

.select2-container-active .select2-choice,
.select2-container-active .select2-choices {
    border: 1px solid color(primary-300);
    outline: none;

    box-shadow: 0 0 5px colorAlpha(base-700, 0.3);
}

.select2-dropdown-open .select2-choice {
    border-bottom-color: transparent;
    box-shadow: 0 1px 0 #{color(base-000)} inset;

    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    background-color: color(base-300);
    /* autoprefixer: off */
    background-image: linear-gradient(to top, #{color(base-000)} 0%, #{color(base-300)} 50%);
}

.select2-dropdown-open.select2-drop-above .select2-choice,
.select2-dropdown-open.select2-drop-above .select2-choices {
    border: 1px solid color(primary-300);
    border-top-color: transparent;
    /* autoprefixer: off */
    background-image: linear-gradient(to bottom, #{color(base-000)} 0%, #{color(base-300)} 50%);
}

.select2-dropdown-open .select2-choice .select2-arrow {
    background: transparent;
    border-left: none;
}

.select2-dropdown-open .select2-choice .select2-arrow b {
    background-position: -18px 1px;
}

.select2-hidden-accessible {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

/* results */
.select2-results {
    max-height: 200px;
    padding: 0 0 0 4px;
    margin: 4px 4px 4px 0;
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
}

.select2-results ul.select2-result-sub {
    margin: 0;
    padding-left: 0;
}

.select2-results li {
    list-style: none;
    display: list-item;
    background-image: none;
}

.select2-results li.select2-result-with-children > .select2-result-label {
    @include font-weight(semibold);
}

.select2-results .select2-result-label {
    padding: 3px 7px 4px;
    margin: 0;
    cursor: pointer;

    min-height: 1em;

    user-select: none;
}

.select2-results-dept-1 .select2-result-label {
    padding-left: 20px;
}

.select2-results-dept-2 .select2-result-label {
    padding-left: 40px;
}

.select2-results-dept-3 .select2-result-label {
    padding-left: 60px;
}

.select2-results-dept-4 .select2-result-label {
    padding-left: 80px;
}

.select2-results-dept-5 .select2-result-label {
    padding-left: 100px;
}

.select2-results-dept-6 .select2-result-label {
    padding-left: 110px;
}

.select2-results-dept-7 .select2-result-label {
    padding-left: 120px;
}

.select2-results .select2-highlighted {
    background: color(primary-300);
    color: color(base-000);
}

.select2-results li em {
    background: color(base-050);
    font-style: normal;
}

.select2-results .select2-highlighted em {
    background: transparent;
}

.select2-results .select2-highlighted ul {
    background: color(base-000);
    color: color(base-700);
}

.select2-results .select2-no-results,
.select2-results .select2-searching,
.select2-results .select2-selection-limit {
    background: color(base-100);
    display: list-item;
    padding-left: 5px;
}

/*
disabled look for disabled choices in the results dropdown
*/
.select2-results .select2-disabled.select2-highlighted {
    color: color(base-600);
    background: color(base-100);
    display: list-item;
    cursor: default;
}

.select2-results .select2-disabled {
    background: color(base-100);
    display: list-item;
    cursor: default;
}

.select2-results .select2-selected {
    display: none;
}

.select2-more-results.select2-active {
    background: #{color(base-100)} url('../img/select2-spinner.gif') no-repeat 100%;
}

.select2-more-results {
    background: color(base-100);
    display: list-item;
}

/* disabled styles */

.select2-container.select2-container-disabled .select2-choice {
    background-color: color(base-100);
    background-image: none;
    border: 1px solid #ddd;
    cursor: default;
}

.select2-container.select2-container-disabled .select2-choice .select2-arrow {
    background-color: color(base-100);
    background-image: none;
    border-left: 0;
}

.select2-container.select2-container-disabled .select2-choice abbr {
    display: none;
}

/* multiselect */

.select2-container-multi .select2-choices {
    height: auto !important;
    height: 1%;
    margin: 0;
    padding: 0;
    position: relative;

    border: 1px solid color(base-500);
    border-radius: $base-border-radius;

    cursor: text;
    overflow: hidden;

    background-color: color(base-000);
}

.select2-locked {
    padding: 3px 5px 3px 5px !important;
}

.select2-container-multi .select2-choices {
    min-height: 26px;
}

.select2-container-multi.select2-container-active .select2-choices {
    border: 1px solid color(primary-300);
    outline: none;

    box-shadow: 0 0 5px colorAlpha(base-700, 0.3);
}

.select2-container-multi.select2-dropdown-open .select2-choices {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.select2-container-multi .select2-choices li {
    float: left;
    list-style: none;
}

html[dir='rtl'] .select2-container-multi .select2-choices li {
    float: right;
}

.select2-container-multi .select2-choices .select2-search-field {
    margin: 0;
    padding: 0;
    white-space: nowrap;
}

.select2-container-multi .select2-choices .select2-search-field input {
    padding: 5px;
    margin: 1px 0;

    font-family: sans-serif;
    font-size: 100%;
    color: color(base-600);
    outline: 0;
    border: 0;
    box-shadow: none !important;
    background: transparent !important;
}

.select2-container-multi
    .select2-choices
    .select2-search-field
    input.select2-active {
    background: #{color(base-000)} url('../img/select2-spinner.gif') no-repeat 100% !important;
}

.select2-default {
    color: #999;
}

.select2-container-multi .select2-choices .select2-search-choice {
    padding: 3px 5px 3px 18px;
    margin: 6px 0 3px 5px;
    position: relative;

    line-height: 13px;
    color: #333;
    cursor: default;
    border: 1px solid color(base-500);

    border-radius: 3px;

    box-shadow: 0 0 2px #{color(base-000)} inset, 0 1px 0 colorAlpha(base-700, 0.05);

    background-clip: padding-box;

    user-select: none;

    background-color: #e4e4e4;
    /* autoprefixer: off */
    background-image: linear-gradient(
        to top,
        #{color(base-100)} 20%,
        #f0f0f0 50%,
        #e8e8e8 52%,
        #{color(base-300)} 100%
    );
}

html[dir='rtl']
    .select2-container-multi
    .select2-choices
    .select2-search-choice {
    margin-left: 0;
    margin-right: 5px;
}

.select2-container-multi
    .select2-choices
    .select2-search-choice
    .select2-chosen {
    cursor: default;
}

.select2-container-multi .select2-choices .select2-search-choice-focus {
    background: #d4d4d4;
}

.select2-search-choice-close {
    display: block;
    width: 12px;
    height: 13px;
    position: absolute;
    right: 3px;
    top: 4px;

    font-size: 1px;
    outline: none;
    background: url('../img/select2.png') right top no-repeat;
}

html[dir='rtl'] .select2-search-choice-close {
    right: auto;
    left: 3px;
}

.select2-container-multi .select2-search-choice-close {
    left: 3px;
}

.select2-container-multi
    .select2-choices
    .select2-search-choice
    .select2-search-choice-close:hover {
    background-position: right -11px;
}

.select2-container-multi
    .select2-choices
    .select2-search-choice-focus
    .select2-search-choice-close {
    background-position: right -11px;
}

/* disabled styles */
.select2-container-multi.select2-container-disabled .select2-choices {
    background-color: color(base-100);
    background-image: none;
    border: 1px solid #ddd;
    cursor: default;
}

.select2-container-multi.select2-container-disabled
    .select2-choices
    .select2-search-choice {
    padding: 3px 5px 3px 5px;
    border: 1px solid #ddd;
    background-image: none;
    background-color: color(base-100);
}

.select2-container-multi.select2-container-disabled
    .select2-choices
    .select2-search-choice
    .select2-search-choice-close {
    display: none;
    background: none;
}

/* end multiselect */

.select2-result-selectable .select2-match,
.select2-result-unselectable .select2-match {
    text-decoration: underline;
}

.select2-offscreen,
.select2-offscreen:focus {
    clip: rect(0 0 0 0) !important;
    width: 1px !important;
    height: 1px !important;
    border: 0 !important;
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden !important;
    position: absolute !important;
    outline: 0 !important;
    left: 0px !important;
    top: 0px !important;
}

.select2-display-none {
    display: none;
}

.select2-measure-scrollbar {
    position: absolute;
    top: -10000px;
    left: -10000px;
    width: 100px;
    height: 100px;
    overflow: scroll;
}

/* Retina-ize icons */

@media only screen and (min-resolution: '2dppx') {
    .select2-search input,
    .select2-search-choice-close,
    .select2-container .select2-choice abbr,
    .select2-container .select2-choice .select2-arrow b {
        background-image: url('../img/select2x2.png') !important;
        background-repeat: no-repeat !important;
        background-size: 60px 40px !important;
    }

    .select2-search input {
        background-position: 100% -21px !important;
    }
}
