.password-confirmation {
    .password-strength,
    .password-confirmation-mismatch {
        text-align: left;
        font-size: 12px;
        margin-top: -10px;
    
        &.very_weak {
            color: var(--error-300);
        }
    
        &.weak {
            color: var(--alert-300);
        }
    
        &.good, &.strong, &.good_job {
            color: var(--success-300);
        }
    }

    .password-confirmation-mismatch {
        color: var(--error-300);
    }
}
