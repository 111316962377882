@import "@simonwep/pickr/src/scss/themes/monolith";
@import "pickr";
@import "diff2html/bundles/css/diff2html.min";

@import "at-who";
@import "cropper";
@import "mobiscroll";
@import "quill";
@import "select2";
@import "vue-multiselect";
@import "vue2-datepicker";
@import "vue-draggable";

// intl-tel-input
:root {
    --iti-path-flags-1x: url('../img/flags.webp');
    --iti-path-flags-2x: url('../img/flags@2x.webp');
    --iti-path-globe-1x: url('../img/globe.webp');
    --iti-path-globe-2x: url('../img/globe@2x.webp');
}

@import "intl-tel-input/build/css/intlTelInput.css";
