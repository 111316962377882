@charset "UTF-8";

@use "sass:math";

// Normalization
@import "normalize.css/normalize.css";

// Main manifest file

@import "abstracts/functions";
@import "abstracts/variables";
@import "abstracts/mixins";
@import "abstracts/icons";

// Base styles

@import "default/layout";
@import "default/commons";

// Components

@import "default/components/alerts";
@import "default/components/badges";
@import "default/components/buttons";
@import "default/components/forms";
@import "default/components/forms/multi-factor-code-control";
@import "default/components/forms/password-confirmation";
@import "default/components/horizontal-divider";
@import "default/components/icons";
@import "default/components/loader";
@import "default/components/notifications";
@import "default/components/tooltips";

// Pages
@import "default/pages/auth";

// Small down
@media (max-width: $screen-sm-max) {
    @import "small-down/pages/auth";
}

// Third party libraries
@import "vendor/index";
