@import "quill/dist/quill.snow";

$LIST_COUNTERS: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8;
$LIST_STYLE: decimal lower-alpha lower-roman;
$LIST_STYLE_WIDTH: 1.2rem;
$LIST_STYLE_MARGIN: 0.3rem;
$LIST_STYLE_OUTER_WIDTH: $LIST_STYLE_MARGIN + $LIST_STYLE_WIDTH;
$MAX_INDENT: 8;

.quill-editor {
    // See: https://github.com/quilljs/quill/issues/1760#issuecomment-380909776
    white-space: pre-wrap;

    .ql-toolbar {
        white-space: normal;
    }
}

/**
 * Quill.js
 * https://quilljs.com/
 */
 .ql-toolbar {
    background-color: color(base-100);
    border-top-left-radius: $base-border-radius;
    border-top-right-radius: $base-border-radius;
}

.ql-container {
    border-radius: $base-border-radius;

}

.ql-toolbar + .ql-container {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.ql-snow {
    &.ql-toolbar,
    &.ql-container {
        border-color: color(base-500);

        &.ql-disabled {
            border-color: transparent;
        }
    }

    .ql-editor h3 {
        border-bottom: none;
    }

    .ql-tooltip {
        margin-left: box-model(m) * 8;
    }

    .ql-picker {
        color: color(base-700);
    }

    .ql-stroke {
        stroke: color(base-700);
    }

    .ql-fill {
        fill: color(base-700);
    }
}

.disabled > .ql-snow.ql-toolbar {
    border-color: transparent;
}

.hide-quill-tooltip {
    .ql-tooltip {
        display: none;
    }
}

// TODO: remove in phase four
.ql-clipboard {
    left: -100000px;
    height: 1px;
    overflow-y: hidden;
    position: absolute;
    top: 50%;
}

.ql-editor {
    &.ql-blank::before {
        @include font-size(base);

        color: color(base-500);
        font-style: normal;
    }

    @for $num from 1 through $MAX_INDENT {
        .ql-indent-#{$num}:not(.ql-direction-rtl) {
            padding-left: $num * 3rem;
        }

        li.ql-indent-#{$num}:not(.ql-direction-rtl) {
            padding-left: ($num * 3rem) + $LIST_STYLE_OUTER_WIDTH;
        }

        .ql-indent-#{$num}.ql-direction-rtl.ql-align-right {
            padding-right: $num * 3rem;
        }

        li.ql-indent-#{$num}.ql-direction-rtl.ql-align-right {
            padding-right: ($num * 3rem) + $LIST_STYLE_OUTER_WIDTH;
        }
    }
}

// TODO: replace this with proper color picker
.ql-color .ql-picker-options [data-value=custom-color] {
    background: none !important;
    width: 100% !important;
    height: 26px !important;
    text-align: center;

    &::before {
        content: 'Custom Color';
    }

    &:hover {
        border-color: transparent !important;
    }
}

@each $alignment in center, left, right, justify {
    .ql-align-#{$alignment} {
        text-align: $alignment;
    }
}

.ql-list {
    list-style-type: none;

    > li::before {
        content: '\2022';
        display: inline-block;
        white-space: nowrap;
        width: 1.2rem;
    }

    li {
        &:not(.ql-direction-rtl)::before {
            margin-left: -1.5rem;
            margin-right: 0.3rem;
            text-align: right;
        }
    }
}

ol.ql-list {
    list-style-type: none;

    li {
        counter-reset: $LIST_COUNTERS;
        counter-increment: list-0;

        &::before {
            content: counter(list-0, decimal) '. ';
        }

        @for $num from 1 through $MAX_INDENT {
            &.ql-indent-#{$num} {
                counter-increment: list-#{$num};

                &::before {
                    content: unquote('counter(list-#{$num}, #{ nth($LIST_STYLE, ($num % 3) + 1) })') '. ';
                }
            }

            @if $num < $MAX_INDENT {
                &.ql-indent-#{$num} {
                    counter-reset: slice($LIST_COUNTERS, $num + 1, $MAX_INDENT);
                }
            }
        }
    }
}


