.auth {
    position: relative;
    min-height: 100vh;
    background-color: color(base-100);

    &::after {
        content: '';
        position: absolute;
        background-image: image-url('login-bg.png');
        background-repeat: no-repeat;
        background-position: 0 100%;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: block;
        opacity: 0.3;
        z-index: 0;
    }

    .container {
        padding: box-model(xxxl);
        background-color: color(base-000);
        border-radius: $base-border-radius * 2;
        box-shadow: $login-box-shadow;
        position: absolute;
        left: 50%;
        top: 50%;
        z-index: 1;
        width: box-model(xs) * 44;
        max-width: 70vw;
        transform: translate3d(-50%, -50%, 0);

        &:has(.auth-container.back-to-login) {
            padding-top: 0;
        }

        .back-to-login {
            header {
                margin: 0 calc(0px - box-model(xxxl));
                padding: box-model(m);
                padding-bottom: box-model(xs);
                border-bottom: solid 1px color(base-300);

                a {
                    color: inherit;
                }

                i {
                    font-size: icon-size(xs);
                }
            }
        }
    }

    .auth-container {
        transition: opacity .2s ease-in;
        opacity: 1;

        &.loading {
            opacity: 0;
        }
    }

    h1 {
        text-align: center;
        margin: 0;
    }

    section {
        padding: box-model(xxxl, 0);
    }

    input[type="text"],
    input[type="email"],
    input[type="password"] {
        height: box-model(xxl);
    }

    .actions {
        margin: 0;
        display: flex;
        flex-direction: row;
        text-align: left;

        &.links {
            text-align: center;
        }

        + .actions {
            margin-top: box-model(l);
        }
    }

    .action {
        @include line-height(l);

        flex: 1 1 auto;
    }

    .alert {
        margin-bottom: box-model(l);
    }

    .new-password {
        &.too-short, &.very-weak {
            .control-hint {
                color: color(error-300);
            }
        }

        &.weak .control-hint {
            color: color(alert-300);
        }

        &.strong, &.good, &.good-job {
            .control-hint {
                color: color(success-500);
            }
        }
    }

    .login-divider {
        padding: box-model(l, 0);
    }

    footer {
        ul {
            list-style-type: none;
            padding: 0;
            margin: 0;
        }

        li {
            @include font-size(s);

            display: inline-block;
            margin-right: box-model(l);
        }
    }

    .sso-login {
        .sso-domain.valid {
            margin-bottom: box-model(xs);
        }

        .bookmark-text {
            @include font-size(s);
            color: color(base-500);
            margin-bottom: box-model(l);
        }
    }

    .mfa-validation, .mfa-phone-query {
        .mfa-prompt {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .resend {
            align-self: flex-start;
            cursor: pointer;
            margin-top: box-model(l);
        }

        .text-prompt {
            margin-bottom: box-model(l);
        }

        .remember-me {
            align-self: flex-start;
        }
    }

    .mfa-phone-query {
        .telephone-input {
            width: 100%;

            .iti {
                width: 100%;
            }
        }
    }
}
